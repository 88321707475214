import themeMedia from "./themeMedia";

themeMedia();

Promise.all([
  import("./layout/headerNavigator"),
  import("./layout/footerForm"),
  import("./layout/footerReel"),
  import("./layout/footerBanner"),
  import("./partials/sectionAbout"),
  import("./partials/sectionSlider"),
  import("./partials/sectionSliderServices"),
  import("./partials/sectionCardMap"),
  import("./partials/sectionCardText"),
  import("./partials/sectionCardGallery"),
  import("./partials/sectionGridLink"),
  import("./templates/templateContatti"),
  import("./templates/templateEventi"),
  import("./templates/templateServiziEsclusivi"),
]).then(
  ([
    headerNavigator,
    footerForm,
    footerReel,
    footerBanner,
    sectionAbout,
    sectionSlider,
    sectionSliderServices,
    sectionCardMap,
    sectionCardText,
    sectionCardGallery,
    sectionGridLink,
    templateContatti,
    templateEventi,
    templateServiziEsclusivi,
  ]) => {
    headerNavigator.default();
    footerForm.default();
    footerReel.default();
    footerBanner.default();
    sectionAbout.default();
    sectionSlider.default();
    sectionSliderServices.default();
    sectionCardMap.default();
    sectionCardText.default();
    sectionCardGallery.default();
    sectionGridLink.default();
    templateContatti.default();
    templateEventi.default();
    templateServiziEsclusivi.default();
  }
);
